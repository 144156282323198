@font-face {
  font-family: "Figtree";
  src: url('static/Figtree-Regular.ttf') format('truetype'),
    url('static/Figtree-Regular.ttf') format('trueType');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  font-family: 'Figtree', serif;
  /* Specify the font family */
  margin: 0;
  padding: 0;
  background-color: #efefef;
}

.certificate {
  max-width: 800px;
  /* Maximum width of the certificate section */
  margin: 20px auto;
  /* Center the certificate section and add margin to top/bottom */
  padding: 20px;
  background-color: #ffffff;
  /* Background color for the certificate section */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* Add some shadow for a lifting effect */
}

.certificate__title {
  text-align: center;
  /* Center the text */
  font-size: 2em;
  /* Increase font size */
}

.certificate__artist {
  text-align: center;
  font-size: 1.5em;
  /* Increase font size */
  color: #000000;
  /* Change color to something lighter than the default color */
}

.certificate__collection,
.certificate__artBackground,
.certificate__artistBackground {
  margin-top: 20px;
  /* Add some space before these elements */
}

.certificate__image {
  display: block;
  /* Center the image and add some space before and after */
  max-width: 100%;
  /* Make sure image does not overflow */
}

.certificate__manufactured,
.certificate__bought {
  /* Add some space before these elements */
  font-style: italic;
  /* Change font style to italic */
}

.spec {
  /* Add some space before these elements */
  font-size: 1.75vh;
  /* Decrease font size */
  color: #000000;
  margin-top: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  flex: 50%;
  padding: 10px;
  box-sizing: border-box;
}

h5 {
  position: relative;
  font-size: 2vh;
}

h5::before {
  content: '        ';
  position: absolute;
  top: -10px;
  left: 0;
  width: 15%;
  height: 2px;
  background: black;
}

button {
  background-color: #A70C4C;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5vh;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.center {
  text-align: center;
  align-items: center;
}

.signature {
  text-align: center;
  width: 100%;
}

.certificate__signature {
  max-width: 40%;
  max-height: auto;
}